import { createGlobalStyle } from 'styled-components';


export const theme = {
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    gray: '#959595'
  },
  breakpoints: {
    mobile: '425px',
    tablet: '768px',
    tabletLarge: '1024px',
    desktop: '1280px'
  },
  sizes: {
    desktop: 1280,
    tabletLarge: 1024,
    tablet: 768,
    tabletPlus: 769,
    phone: 425
  }
};


const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'suisse-regular';
    src: url('./static/SuisseIntl-Book.eot');
    src: url('./static/SuisseIntl-Book.eot?#iefix') format('embedded-opentype'),
         url('./static/SuisseIntl-Book.woff2') format('woff2'),
         url('./static/SuisseIntl-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'suisse-works';
    src: url('./static/SuisseWorks-Regular.eot');
    src: url('./static/SuisseWorks-Regular.eot?#iefix') format('embedded-opentype'),
         url('./static/SuisseWorks-Regular.woff2') format('woff2'),
         url('./static/SuisseWorks-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  *:focus {
    outline: none;
  }
  
  body {
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
    margin: 0 auto;
    min-height: 100%;
    overflow-x: hidden;
    padding: 0;
    position: relative;
    width: 100%;
  }
  
  a {
    cursor: pointer;
    text-decoration: none;
    color: ${theme.colors.white};
  }

  input {
    border-radius: 0;
    -webkit-appearance: none;
    
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${theme.colors.white};
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${theme.colors.black};
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: ${theme.colors.black};
    }

    &[type=email] {
      width: 100%;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  select {
    background: transparent;
  }

  input[type=email] {
    -webkit-appearance: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
  }

  @keyframes autofill {
    100% {
      background: transparent;
      color: inherit;
    }
  }

  @-webkit-keyframes autofill {
    100% {
      background: transparent;
      color: inherit;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: $black;
    -webkit-box-shadow: inset 0 0 0px 9999px transparent;
    transition: background-color 5000s ease-in-out 0s;
  }
`;
export default GlobalStyle;