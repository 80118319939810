import React, { Component } from 'react'
import styled from 'styled-components'
import media from './common/mediaQuery'
import { Caption } from './common/type'
import { Anchor } from '../components/common/anchor'

class Footer extends Component {
  render() {
    return (
      <FooterWrapper>
        <Caption>
          <Anchor href="mailto:hello@oneilolonade.com">hello@oneilolonade.com</Anchor>
        </Caption>
      </FooterWrapper>
    );
  }
};

export default Footer;

const FooterWrapper = styled.div`
  bottom: 0;
  padding: 20px 20px;
  width: 100%;

  ${media.tablet`
    padding: 20px 40px;
    position: fixed;
  `}

  ${media.tabletLarge`
    padding: 20px 60px;
  `}

  p {
    margin: 0 auto;
    text-align: center;
    width: fit-content;
  }
`;