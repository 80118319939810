import styled from 'styled-components'
import media from './mediaQuery'

export const H1 = styled.h1`
  font-family: "helvetica";
  font-size: 1.625rem;
  font-weight: normal;
  letter-spacing: -.5px;
  line-height: 2.125rem;
  margin: 0;

  ${media.tablet`
    font-size: 2.125rem;
    line-height: 2.375rem;
  `}
`;

export const H2 = styled.h2`
  font-family: "helvetica";
  font-size: 1.25rem;
  font-weight: normal;
  letter-spacing: -.5px;
  line-height: 1.75rem;
  margin: 0;

  ${media.tablet`
    font-size: 1.625rem;
    line-height: 1.8125rem;
  `}

  +p {
    margin: 4px 0 0;
  }
`;

export const Type = styled.p`
  font-family: "helvetica";
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.4rem;
  margin: 0;

  ${media.tablet`
    line-height: 1.85rem;
  `}
`;

export const Label = styled.p`
  font-family: 'helvetica';
  letter-spacing: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1.5625rem;
  margin: 0;
  -webkit-font-smoothing: antialiased;
`;

export const Caption = styled.p`
  font-family: "helvetica";
  font-size: .8rem;
  font-weight: normal;
  letter-spacing: .5px;
  line-height: 1.15rem;
  margin: 0;

  ${media.tablet`
    line-height: 1.1rem;
  `}

  a {
    font-family: "helvetica";
    font-size: .8rem;
    font-weight: normal;
    letter-spacing: .5px;
    line-height: 1.15rem;
    margin: 0;

    ${media.tablet`
      line-height: 1.1rem;
    `}
  }
`;