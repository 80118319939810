import styled from 'styled-components'
import media from './mediaQuery'
import { theme } from '../global'

export const Anchor = styled.a`
  cursor: pointer;
  color: ${theme.colors.white};
  font-family: "helvetica";
  font-size: 1rem;
  font-size: 1.25rem;
  font-weight: normal;
  letter-spacing: -.5px;
  line-height: 1.75rem;
  margin: 0;
  position: relative;
  transition: color 0.4s ease-in-out;

  ${media.tablet`
    font-size: 1.625rem;
    line-height: 1.8125rem;
  `}

  &:after {
    content: '';
    background-color: ${theme.colors.white};
    bottom: -4px;
    height: 1px;
    left: 0;
    position: absolute;
    right: 100%;
    transition: right 0.4s ease-in-out;
  }

  &:hover {
    /* color: ${theme.colors.gray}; */

    &:after {
      right: 0;
    }
  }
`;
