import React from 'react'
import PropTypes from 'prop-types'
// import { useStaticQuery, graphql } from 'gatsby'
import styled from "styled-components"
import GlobalStyle from './global'
import Footer from './footer'
import media from './common/mediaQuery'

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <GlobalStyle />
      <Wrapper>{children}</Wrapper>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Wrapper = styled.div`
  height: 100%;
  padding: 60px 20px;
  
  ${media.tablet`
    min-height: 100vh;
    padding: 80px 40px;
  `}

  ${media.tabletLarge`
    padding: 80px 60px;
  `}
`;
